<template>
  <v-container v-if="getUser">
    <v-card class="d-flex flex-column flex-md-row">
      <div class="account_menu">
        <div class="d-flex align-center pa-4">
          <img
            class="account_user_pick"
            @click="toggleShowEditAvatar"
            :src="getUserAvatar"
            :alt="`${fullName} avatar`"
          />
          <div class="account_user_name ml-3">{{ fullName }}</div>
        </div>
        <avatar
          :show="show"
          @taggleShow="toggleShowEditAvatar"
          @uploadHandler="avatarUpload"
        />

        <v-divider></v-divider>

        <ul class="d-none d-md-block">
          <li v-for="(item, index) in items" :key="index">
            <router-link
              class="account_menu_link d-flex align-center pl-4 pr-4 pt-2 pb-2"
              :to="{ name: item.link }"
              ><v-icon class="mr-2">{{ item.icon }}</v-icon>
              {{ item.title }}</router-link
            >
          </li>
          <li
            class="account_menu_link account_menu_link_logout d-flex align-center pl-4 pr-4 pt-2 pb-2"
            @click="logout()"
          >
            <v-icon class="mr-2">mdi-exit-to-app</v-icon> Вийти
          </li>
        </ul>
      </div>

      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
const Avatar = () => import('@/shared/components/Avatar')

export default {
  name: 'PageComponent',
  data: () => ({
    items: [
      {
        title: 'Моя Сторiнка',
        icon: 'mdi-account',
        link: 'MyPage'
      },
      {
        title: 'Мої друзі',
        icon: 'mdi-account-group',
        link: 'MyFriends'
      },
      {
        title: 'Персональнi данi',
        icon: 'mdi-account-edit',
        link: 'Info'
      },
      {
        title: 'Налаштування',
        icon: 'mdi-cog',
        link: 'AccountSettings'
      }
    ],
    show: false
  }),
  components: {
    Avatar
  },
  computed: {
    ...mapGetters('user', ['getUser']),
    fullName () {
      if (!this.getUser) return ''
      return `${this.getUser.firstName} ${this.getUser.lastName}`
    },
    getUserAvatar () {
      if (!this.getUser) return ''
      return (
        this.getUser.avatar || 'https://randomuser.me/api/portraits/men/85.jpg'
      )
    }
  },
  methods: {
    logout () {
      this.$store.commit('auth/logOut')
      this.$cookies.keys().forEach((cookie) => this.$cookies.remove(cookie))
      this.$store.commit('user/resetPrivacyState')
      this.$router.push('/').catch(() => {})
    },
    toggleShowEditAvatar () {
      this.show = !this.show
    },
    avatarUpload (path) {
      this.$store.commit('user/updateUserPhoto', path)
    }
  }
}
</script>
